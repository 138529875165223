import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { Col, Row } from "styled-bootstrap-grid";
import breakpoints from "../styles/breakpoints";
import Container from "../components/Core/Container";

const Section = styled.section`
	margin-top: 3.125rem;

	@media only screen and (min-width: ${breakpoints.xl}) {
		padding: 0;
	}
`;

const Subheading = styled.div`
	margin: 0 0 1.25rem;
`;

const GridContainer = styled.ul`

    list-style: none;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-gap: 1.25rem;
    padding: 0;

    .grid-item {
        background-size: cover;
        background-repeat: no-repeat;
        height: 15.75rem;
        width: 100%;
        box-shadow: 0 0 1.25rem rgb(0 0 0 / 30%);
        border-radius: .25rem;
        background-position: center;

        a {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            justify-content: flex-end;
            text-decoration: none;
            padding: 1.188rem;
            box-sizing: border-box;
            color: var(--white);
            background: rgba(0,0,0,0.4);
            position: relative;
            font-size: 1.5rem;

            p {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                text-align: center;
                font-weight: bold;
            }
        }
    }

	@media only screen and (min-width: 1024px) {
        grid-template-columns: 1fr 1fr 1fr;

        .grid-item {
            height: 24.5rem;

            &:hover,
            &:focus-within {
                transform: scale(1.025);
                transform-origin: 50% 50%;
                transition: transform .3s ease-out;
            }
        }

	}

`;

export default function Blog({data}) {
  const blogposts = data.blogs.nodes;
	return (
  <Section>
    <Container>
      <Row>
        <Col md={12}>
          <h1>Inspiration</h1>
          <Subheading>
            If you need a little inspiration for your next trip, here are some articles from our team to guide your next adventure.
          </Subheading>
          <GridContainer className="grid-container">
            {blogposts.map((item, index) => {
                  let itemLink = item.slug.current;

                  if (itemLink && !(itemLink.startsWith("/"))){
                    itemLink = `/${  itemLink}`;
                  }
                  return (
                    <li key={index} className="grid-item" style={{backgroundImage: `url(${  item.image.image.asset.url  })`}}>
                      <Link to={itemLink}>
                        <p>{item.title}</p>
                      </Link>
                    </li>
                      );
                  })}
          </GridContainer>
        </Col>
      </Row>
    </Container>
  </Section>
	);
}


export const query = graphql`
	query blogQuery {
        regions: allSanityRegion {
			nodes {
				name
				id
				slug {
					current
				}
			}
		}
        settings: sanitySiteSettings(_id: {eq: "siteSettings"})  {
            description
            id
            keywords
            primaryColor {
                hex
            }
            secondaryColor {
                hex
            }
            title
            logo {
                asset {
                    fixed {
                        src
                    }
                }
            }
            favicon {
                asset {
                    fixed(height: 48, width: 48) {
                        src
                    }
                }
            }
            image {
            asset {
            fixed(height: 600, width: 1200) {
              src
            }
            }
          }
          signUpDisplay
			    signUpBackgroundColor
			    signUpHeading
			    zapierEndpoint
        }
        blogs: allSanityBlogPost {
          nodes {
          slug {
            current
          }
          title
          image {
            alt
            image {
              asset {
                      url
                  }
            }
          }
          }
        }
	}
`;